/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, Heading, jsx } from "theme-ui";
import ContentWithImage from "../components/ContentWithImage";
import HexagonList from "../components/HexagonList";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const Commercial = ({ data }) => {
  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="Commercial roof repair"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerBottomIconAlt="Icon with blue buildings"
      headerBottomIconSrc={data.BuildingsIcon.publicURL}
      headerFancyBanner={false}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Commercial" />

      <Box as="article" sx={{ my: [8, null, 11] }}>
        <Container>
          <ContentWithImage
            imageAlt="Man installing roof shingles."
            imageURL={data.CommercialServices.childImageSharp.gatsbyImageData}
            linkArrTitle="roof types we service"
            linksEnabled={false}
            titleText="Commercial Roof Repair Services in the Greater Phoenix Area"
          >
            <p>
              In central Arizona, repairs and periodic coatings can add years to
              any roof’s serviceable life. Whatever type of roof your building
              has, help it last as long as possible by getting regular care,
              inspections and maintenance from an AZ-certified roofing company.
            </p>

            <p>
              Legacy Repair technicians are experienced roof repair specialists.
              We will inspect and repair any site in an efficient, transparent
              manner, ensuring that your building is well-maintained and that
              you are aware of your roof’s health and lifespan.
            </p>

            <Heading as="h2" variant="styles.h3" sx={{ mt: 6 }}>
              Some Popular Flat Roof Materials
            </Heading>

            <p>
              Most commercial roofs in Arizona are flat or low-slope roofs.
              These are typical treatments and repair services we offer for
              those roof types; however, we are prepared to care for any type of
              roof your building may have.
            </p>

            <Heading as="h3" variant="styles.h4" sx={{ mt: 6 }}>
              Modified Bitumen
            </Heading>

            <p>
              This is an asphalt roof membrane that can make a roof more water
              and fire-resistant. It can also increase your building’s energy
              efficiency. This is a heat-applied material that can be sealed
              with elastomeric coatings or asphalt cement.
            </p>

            <Heading as="h3" variant="styles.h4" sx={{ mt: 6 }}>
              Hot Mop
            </Heading>

            <p>
              A hot-mop (or hot) roof is installed - and repaired - by spreading
              hot asphalt onto a roof’s base layer with a mop. This layer is
              usually applied under a rolled roofing membrane to strengthen the
              roof overall. Hot mop is a long-lasting roof type, but like all
              others, it needs occasional inspection and repairs.{" "}
            </p>

            <Heading as="h3" variant="styles.h4" sx={{ mt: 6 }}>
              Thermoplastic Polyolefin (TPO)
            </Heading>

            <p>
              TPO is a single-ply roof system that can be fastened in a number
              of different ways: ballasted, fully adhered, or attached with
              screws. This roof type has excellent heat and UV resistance, which
              is why it is so popular for commercial buildings in Arizona.
            </p>

            <p>
              However, it does need maintenance and check-ups to ensure that it
              is not aging or degrading too quickly.
            </p>

            <Heading as="h3" variant="styles.h4" sx={{ mt: 6 }}>
              Self-Adhered
            </Heading>

            <p>
              This roof type is made of a nailable asphalt-based ply attached to
              a self-adhering membrane, mid ply, and cap sheet. Easy to put in
              place, self-adhering roofs are popular for their simplicity and
              environmentally-friendly installation process. However, as with
              all roofs, they can degrade with time and extreme weather. Their
              T-laps need to be inspected and occasionally re-sealed to protect
              the underlayment from serious damage. The material is also
              susceptible to “creep,” or slow movement over time.
            </p>

            <Heading as="h3" variant="styles.h4" sx={{ mt: 6 }}>
              PVC
            </Heading>

            <p>
              Polyvinyl Chloride (PVC) roofs are a single-ply plastic membrane
              that is generally heat-welded into place. This material is fairly
              fire-resistant and a good choice for flat roofs, where water can
              pool during summer storms. However, if the roof was not sealed
              properly or if improper drainage leads to extended ponding, PVC
              may need to be patched or re-sealed.
            </p>
          </ContentWithImage>
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "commercial-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    BuildingsIcon: file(relativePath: { eq: "city-buildings.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    CommercialServices: file(relativePath: { eq: "commercial-service.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Commercial;
